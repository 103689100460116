
import { Component, Vue } from "vue-property-decorator";
// import Navbar from "@/components/Utility/Navbar.vue";
// import Footer from "@/components/Utility/Footer.vue";
import Carousel from "@/components/Home/Carousel.vue";
import MobileSwiper from "@/components/Home/MobileSwiper.vue";
import SelectedArticle from 'fdtigermaster-client-sdk/lib/src/SelectedArticle/SelectedArticle';
import ISelectedArticle from 'fdtigermaster-client-sdk/lib/src/Entity/ISelectedArticle';

@Component({
  components: {
    // Navbar,
    // Footer,
    Carousel,
    MobileSwiper,
  },
})
export default class Home extends Vue {
  //data
  clickL1 = [
    {
      name: "安裝服務",
      img: "fdtiger-offical-site-img/home/service_01.jpg",
      index: 0,
    },
    {
      name: "水電服務",
      img: "fdtiger-offical-site-img/home/service_02.jpg",
      index: 1,
    },
    {
      name: "清潔服務",
      img: "fdtiger-offical-site-img/home/service_03.jpg",
      index: 2,
    },
    {
      name: "裝潢整修",
      img: "fdtiger-offical-site-img/home/service_04.jpg",
      index: 3,
    },
    {
      name: "搬運服務",
      img: "fdtiger-offical-site-img/home/service_05.jpg",
      index: 4,
    },
    {
      name: "家電服務",
      img: "fdtiger-offical-site-img/home/service_06.jpg",
      index: 5,
    },
    {
      name: "房屋漏水",
      img: "fdtiger-offical-site-img/home/service_07.jpg",
      index: 6,
    },
    {
      name: "消毒病媒",
      img: "fdtiger-offical-site-img/home/service_08.jpg",
      index: 7,
    },
  ];
  articleList:ISelectedArticle[] =[];
  cooperationlist = [
    "fdtiger-offical-site-img/home/tem18.png",
    "fdtiger-offical-site-img/home/tem19.png",
    "fdtiger-offical-site-img/home/tem20.png",
    "fdtiger-offical-site-img/home/tem21.png",
  ];
  isShow = true;
  openApp = "";
  prepage = 4;
  nowpage = 1;
  isActive = 1;
  device = 0;
  attributename = "href";

  //hook
  async mounted(): Promise<void> {
    const selectedArticle = new SelectedArticle();
    const res = await selectedArticle.selectedArticleGetByDevice(0) as any;
    if (res.length > 3) {
      this.articleList = res.slice(0, 3);
    } else {
      this.articleList = res;
    }
  
  }
  
  //computed
  //會出現的那幾張
  get pageslist(): string[] {
    let start = (this.nowpage - 1) * this.prepage;
    return this.cooperationlist.slice(start, start + this.prepage);
  }
  //總共頁數
  get totalpage(): number {
    return Math.ceil(this.cooperationlist.length) / this.prepage;
  }

  //methods
  //識別裝置是apple/android
  appSource(): void {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      this.openApp =
        "https://apps.apple.com/tw/app/%E5%B8%AB%E8%99%8E%E4%BE%86%E4%BA%86/id1495518547";
    } else {
      this.openApp =
        "https://play.google.com/store/apps/details?id=app.viuto.www.tiger_master&hl=zh_TW&gl=US";
    }
  }
  //合作夥伴按鈕點點
  changePages(item: number): void {
    let page = item;
    if (page < 1) {
      this.nowpage = 1;
    } else if (page > this.totalpage) {
      this.nowpage = this.totalpage;
    } else {
      this.nowpage = page;
    }
    this.isActive = page;
  }
  //合作夥伴按鈕箭頭
  moveOnePage(move: number): void {
    let page = this.nowpage + move;
    if (page < 1) {
      this.nowpage = this.totalpage;
      this.isActive = this.totalpage;
    } else if (page > this.totalpage) {
      this.nowpage = 1;
      this.isActive = 1;
    } else {
      this.nowpage = page;
      this.isActive = page;
    }
  }
}
