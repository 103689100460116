
import { Component, Vue, Prop } from "vue-property-decorator";
import tigermaster from "fdtigermaster-client-sdk";
import ICarouselBanner from "fdtigermaster-client-sdk/lib/src/Entity/ICarouselBanner";

@Component
export default class Carousel extends Vue {
  @Prop() device: number;

  // data
  isActive = 0;
  timer = 0;
  index = 0;
  show = 0;
  imgs: ICarouselBanner[] = [];
  clicked = null;

  // hook
  created(): void {
    this.getAd();
    clearInterval(this.timer);
    this.timer = 0;
    this.timer = setInterval(() => {
      this.next();
    }, 5000);
  }

  // methods
  changeShow(index: number): void {
    this.show = index;
    this.isActive = index;
  }
  async getAd(): Promise<void> {
    const res = await tigermaster.carouselBanner.get(this.device);
    this.imgs = res as unknown as ICarouselBanner[];
  }
  next(): void {
    this.show++;
    this.isActive++;
    if (this.show == this.imgs.length) {
      this.show = 0;
      this.isActive = 0;
    }
  }
  prev(): void {
    this.show--;
    this.isActive--;
    if (this.show == 0) {
      this.show = 0;
      this.isActive = 0;
    }
    if (this.show < 0) {
      this.show = this.imgs.length - 1;
      this.isActive = this.imgs.length - 1;
    }
  }
}
